import React from 'react';

export default function Accordion({ active, setActive, title, content, id }) {
  const isActive = active === id;

  return (
    <div
      className="pb-1 w-full cursor-pointer"
      aria-expanded={isActive}
      id={`tab-${id}`}
      onClick={() => setActive(isActive ? null : id)}
      onKeyDown={(event) =>
        (event.key === 'Enter' || event.key === ' ') && setActive(id)
      }
      role="tab"
      tabIndex="0"
    >
      {isActive ? content : title}
    </div>
  );
}
