import React from 'react';

function ExactMatchButton({ exact, onClick }) {
  return (
    <button
      type="button"
      className={`absolute right-0 text-sm border-1 border rounded-full px-3 ${
        exact ? 'bg-purple text-white' : null
      }`}
      onClick={onClick}
      aria-pressed={exact}
      aria-label="Exact match toggle"
    >
      Exact match
    </button>
  );
}

export default ExactMatchButton;
