import React from 'react';

function ClearButton({ onClick }) {
  return (
    <button
      type="button"
      className="absolute right-10 text-primary"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      clear
    </button>
  );
}

export default ClearButton;
