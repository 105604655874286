import ClearButton from 'components/Search/Filters/SearchModal/ClearButton';
import FilterContainer from 'components/Search/Filters/SearchModal/FilterContainer';
import SectionTitle from 'components/Search/Filters/SectionTitle';
import React from 'react';

import BedBathButtons from './BedBathButtons';
import ExactMatchButton from './ExactMatchButton';

export default function BedroomSelector({
  beds,
  setBeds,
  bdExact,
  setBdExact,
  baths,
  setBaths,
  baExact,
  setBaExact,
}) {
  const bedrooms = ['Studio', '1+', '2+', '3+', '4+', '5+'];
  const bdexact = ['Studio', '1', '2', '3', '4', '5'];
  const bathrooms = ['1+', '2+', '3+', '4+', '5+', 'Shared'];
  const baexact = ['1', '2', '3', '4', '5', 'Shared'];

  const resetRooms = () => {
    setBeds(null);
    setBdExact(null);
    setBaths(null);
    setBaExact(null);
  };

  return (
    <FilterContainer role="group" aria-labelledby="filter-title">
      <ClearButton onClick={resetRooms} />
      <div id="filter-title" className="font-semibold text-xl py-2">
        Rooms
      </div>
      <div
        role="presentation"
        className="relative"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={() => {}}
      >
        <SectionTitle id="bedrooms-title">
          Bedrooms
          <ExactMatchButton
            exact={bdExact}
            onClick={() => setBdExact(!bdExact)}
          />
        </SectionTitle>
        <BedBathButtons
          list={bdExact ? bdexact : bedrooms}
          button={`${beds}`}
          onClick={(e) => setBeds(e.target.innerText.replace('+', ''))}
          aria-labelledby="bedrooms-title"
        />

        <div className="h-6" />
        <SectionTitle id="bathrooms-title">
          Bathrooms
          <ExactMatchButton
            exact={baExact}
            onClick={() => setBaExact(!baExact)}
          />
        </SectionTitle>
        <BedBathButtons
          list={baExact ? baexact : bathrooms}
          button={`${baths}`}
          onClick={(e) => setBaths(e.target.innerText.replace('+', ''))}
          aria-labelledby="bathrooms-title"
        />
      </div>
    </FilterContainer>
  );
}
