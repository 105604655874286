export default function testPriceValidity(minPrice, maxPrice) {
  const min = parseInt(minPrice, 10) ?? 0;
  const max = parseInt(maxPrice, 10) ?? 0;

  if ((minPrice === '' && maxPrice === '') || (!minPrice && !maxPrice))
    return true;

  if (maxPrice === '') return true;
  if (minPrice === '') return true;

  if (min < max) return true;
  if (min < 0 || max < 0) return false;

  return false;
}
