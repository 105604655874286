import useDebounce from 'hooks/input/useDebounce';
import React, { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';

const MAX_SUGGESTIONS = 5;

function SearchAccordion({
  onResultSelect,
  placeholder,
  store,
  setLocation,
  setLocationKey,
  noStack,
}) {
  const [suggestions, setSuggestions] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 240);
  const inputRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const suggestionRefs = useRef([]);

  useEffect(() => {
    if (focusedIndex >= 0 && suggestionRefs.current[focusedIndex]) {
      suggestionRefs.current[focusedIndex].focus();
    } else {
      inputRef.current.focus();
    }
  }, [focusedIndex]);

  useEffect(() => {
    setFocusedIndex(-1);
  }, [suggestions]);

  useEffect(() => {
    if (debouncedSearchTerm) fetchSearch(debouncedSearchTerm);
    else setSuggestions(null);
  }, [debouncedSearchTerm]);

  useEffect(() => inputRef.current.focus(), []);

  const fetchSearch = async (term) => {
    const url = `/api/search/findByQuery?query=${term}`;
    return fetch(url, { method: 'GET' })
      .then((response) => response.json())
      .then((response) => setSuggestions(response.results))
      .catch(() => []);
  };

  const onInputChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const onResultClick = (record) => {
    inputRef.current.value = record?._source?.name.replace(
      ', United States',
      '',
    );
    setSuggestions(null);

    if (onResultSelect) {
      onResultSelect(record._source);
    } else {
      setLocation(record?._source?.name);
      setLocationKey(record?._source?.key);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape' && suggestions?.length) {
      event.stopPropagation();
      setSuggestions(null);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < MAX_SUGGESTIONS - 1 ? prevIndex + 1 : -1,
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (event.key === 'Enter' && focusedIndex >= 0) {
      event.preventDefault();
      onResultClick(suggestions[focusedIndex]);
    } else if (event.key === 'Tab' && suggestions?.length) {
      if (focusedIndex === -1) {
        setFocusedIndex(0);
        event.preventDefault();
      } else if (focusedIndex >= MAX_SUGGESTIONS - 1) {
        setFocusedIndex(-1);
        inputRef.current.focus();
        event.preventDefault();
      } else {
        setFocusedIndex((prevIndex) => prevIndex + 1);
        event.preventDefault();
      }
    }
  };

  return (
    <FocusLock
      disabled={!suggestions?.length}
      className={`w-full m-1 relative ${noStack ? 'z-10' : 'z-50'}`}
    >
      <div
        className={`w-full 
          ${
            suggestions?.length
              ? 'rounded-3xl rounded-b-none border-gray-500 drop-shadow-2xl'
              : 'rounded-full border'
          }
        `}
      >
        <input
          type="search"
          tabIndex="0"
          aria-label="Location search input"
          aria-autocomplete="both"
          aria-owns="search-suggestions"
          onKeyDown={handleKeyDown}
          className={`w-full px-6 h-14 outline-none
          ${suggestions?.length ? 'rounded-3xl rounded-b-none' : 'rounded-full'}
        `}
          placeholder={placeholder || 'Where are you looking to rent?'}
          defaultValue={store?.filters?.location?.replace(
            ', United States',
            '',
          )}
          onChange={onInputChange}
          autoComplete="off"
          ref={inputRef}
        />

        {suggestions?.length ? (
          <ul
            className="w-full absolute min-h-fit bg-white py-5 rounded-b-3xl border-t overflow-hidden"
            id="search-suggestions"
            role="listbox"
          >
            {suggestions?.slice(0, MAX_SUGGESTIONS).map((result, index) => {
              const arr = result._source?.name.split(', ');
              const locationName = result._source?.name?.replace(
                ', United States',
                '',
              );
              return (
                <li
                  ref={(el) => {
                    suggestionRefs.current[index] = el;
                  }}
                  role="option"
                  tabIndex="-1"
                  aria-selected="false"
                  aria-label={locationName}
                  className="flex flex-col px-6 py-2 hover:bg-slate-100 cursor-pointer w-full"
                  key={result._source?.id}
                  onClick={() => onResultClick(result)}
                  onKeyDown={handleKeyDown}
                >
                  <div className="font-semibold" aria-hidden>
                    {arr[0]}
                  </div>
                  <div className="text-sm" aria-hidden>
                    {locationName}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </FocusLock>
  );
}

export default SearchAccordion;
