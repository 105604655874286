import ClearButton from 'components/Search/Filters/SearchModal/ClearButton';
import FilterContainer from 'components/Search/Filters/SearchModal/FilterContainer';
import SectionTitle from 'components/Search/Filters/SectionTitle';
import React, { useEffect, useState } from 'react';

import MoreButtons from './MoreButtons';
import PetPolicyCheckbox from './PetPolicyCheckbox';

const PETS_YES = 'Pet Friendly';
const PETS_NO = 'No Pets';

const PRIVACY_BOTH = 'Both';
const PRIVACY_SHARED = 'Shared Only';
const PRIVACY_PRIVATE = 'Private Only';

function MoreSelector({
  propertyCategory,
  setPropertyCategory,
  roomForRent,
  setRoomForRent,
  pets,
  setPets,
  petPolicy,
  setPetPolicy,
  verifiedFlag,
  setVerified,
}) {
  const propertyType = ['Apartment', 'House', 'Manufactured'];
  const privacy = [PRIVACY_BOTH, PRIVACY_SHARED, PRIVACY_PRIVATE];
  const verified = ['Verified'];
  const pet = [PETS_YES, PETS_NO];

  const propertyCategoryClick = (type) => {
    const newType = type === 'Manufactured' ? 'Mobile' : type;
    const productCategory = propertyCategory || [];
    if (productCategory.includes(newType)) {
      setPropertyCategory(productCategory.filter((el) => el !== newType));
    } else {
      setPropertyCategory([...productCategory, newType]);
    }
  };

  const [privacyValue, setPrivacy] = useState(null);
  const [petsValue, setPetsValue] = useState(null);

  useEffect(() => {
    if (roomForRent === '') setPrivacy(PRIVACY_BOTH);
    if (roomForRent === true) setPrivacy(PRIVACY_SHARED);
    if (roomForRent === false) setPrivacy(PRIVACY_PRIVATE);
  }, [roomForRent]);

  useEffect(() => {
    if (pets === true) setPetsValue(PETS_YES);
    if (pets === false) setPetsValue(PETS_NO);
  }, [pets]);

  const onPrivacyClick = (value) => {
    if (value === PRIVACY_BOTH) setRoomForRent('');
    else if (value === PRIVACY_SHARED) setRoomForRent(true);
    else if (value === PRIVACY_PRIVATE) setRoomForRent(false);

    if (value === privacyValue) {
      setRoomForRent(null);
      setPrivacy(null);
    } else setPrivacy(value);
  };

  const onPetsClick = (value) => {
    if (value === petsValue) {
      setPets(null);
      setPetsValue(null);
    } else {
      if (value === PETS_YES) setPets(true);
      if (value === PETS_NO) setPets(false);
      setPetsValue(value);
    }
  };

  const resetMore = () => {
    setPropertyCategory(null);
    setRoomForRent(null);
    setPrivacy(null);
    setPets(null);
    setPetsValue(null);
    setPetPolicy(false);
    setVerified(false);
  };

  return (
    <FilterContainer>
      <ClearButton onClick={resetMore} />
      <div className="font-semibold text-xl py-2 text-start">More Filters</div>
      <div
        className="relative text-start"
        aria-hidden
        onClick={(e) => e.stopPropagation()}
      >
        <SectionTitle>Property Type</SectionTitle>
        <MoreButtons
          list={propertyType}
          button={propertyCategory?.map((el) =>
            el === 'Mobile' ? 'Manufactured' : el,
          )}
          onClick={(e) => propertyCategoryClick(e.target.innerText)}
        />

        <SectionTitle>Shared/Private</SectionTitle>
        <MoreButtons
          list={privacy}
          button={privacyValue}
          buttonLabel={[
            '',
            'Shared common spaces',
            'Entire place all to yourself',
          ]}
          onClick={(e) => onPrivacyClick(e.target.innerText)}
        />

        <div>
          <SectionTitle>Show Only</SectionTitle>
          <MoreButtons
            list={verified}
            button={verifiedFlag}
            onClick={() => setVerified(!verifiedFlag)}
          />
        </div>
        <div>
          <SectionTitle>Pets</SectionTitle>
          <MoreButtons
            list={pet}
            button={petsValue}
            onClick={(e) => onPetsClick(e.target.innerText)}
          />
        </div>
        <PetPolicyCheckbox petPolicy={petPolicy} setPetPolicy={setPetPolicy} />
      </div>
    </FilterContainer>
  );
}

export default MoreSelector;
