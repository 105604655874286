import Accordion from 'components/form/Accordion';
import React from 'react';

import MoreSelector from './MoreSelector';

function MoreAccordion({
  active,
  setActive,
  propertyCategory,
  setPropertyCategory,
  roomForRent,
  setRoomForRent,
  pets,
  setPets,
  petPolicy,
  setPetPolicy,
  verifiedFlag,
  setVerified,
}) {
  return (
    <Accordion
      active={active}
      setActive={setActive}
      id="more-accordion"
      title={
        <div className="flex flex-col px-6 justify-center w-full h-14 text-sm rounded-full bg-[#E7E7F3]">
          <div className="flex text-lg leading-none text-start">
            More Filters
          </div>
        </div>
      }
      content={
        <div className="flex w-full text-sm rounded-3xl overflow-hidden drop-shadow-2xl">
          <MoreSelector
            propertyCategory={propertyCategory}
            setPropertyCategory={setPropertyCategory}
            roomForRent={roomForRent}
            setRoomForRent={setRoomForRent}
            pets={pets}
            setPets={setPets}
            petPolicy={petPolicy}
            setPetPolicy={setPetPolicy}
            verifiedFlag={verifiedFlag}
            setVerified={setVerified}
          />
        </div>
      }
    />
  );
}

export default MoreAccordion;
