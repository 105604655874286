import React from 'react';

export default function PriceInput({
  value,
  rangeIsValid,
  onChange,
  placeholder,
}) {
  return (
    <div className="relative col-span-3">
      <span className="absolute top-4 left-4 text-sm">$</span>
      <input
        type="number"
        min={0}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={`border rounded-xl ${
          rangeIsValid ? `border-gray-300` : `border-red`
        } p-3 px-7 text-base w-full`}
      />
    </div>
  );
}
