import React from 'react';

function BedBathButtons({ list, button, onClick, ariaLabelledby }) {
  return (
    <div
      key={`${list[0]}-section`}
      className="grid grid-flow-col gap-1 mb-4 mt-1 font-semibold text-lg"
      role="group"
      aria-labelledby={ariaLabelledby}
    >
      {list.map((item) => (
        <div className="flex flex-col" key={`${item}-button`}>
          <button
            type="button"
            className={`border flex px-3 py-3 rounded-xl justify-center ${
              button === item.replace('+', '') || button === true
                ? 'bg-purple text-white'
                : 'bg-slate-50'
            }`}
            onClick={onClick}
            tabIndex="0"
            aria-label={`Select ${item}`}
          >
            {item}
          </button>
        </div>
      ))}
    </div>
  );
}

export default BedBathButtons;
