import ClearButton from 'components/Search/Filters/SearchModal/ClearButton';
import FilterContainer from 'components/Search/Filters/SearchModal/FilterContainer';
import Image from 'next/image';
import RangeDash from 'public/images/range-dash.svg';
import React from 'react';

import PriceInput from './PriceInput';
import testPriceValidity from './testPriceValidity';

export default function PriceSelector({
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
}) {
  const rangeIsValid = testPriceValidity(minPrice, maxPrice);

  const resetPrice = () => {
    setMinPrice('');
    setMaxPrice('');
  };

  const formattedValue = (e) => {
    if (e.target.value < 0) return -1 * e.target.value;
    if (e.target.value.length > 9) return e.target.value.slice(0, 9);
    return e.target.value;
  };

  return (
    <FilterContainer>
      <ClearButton onClick={resetPrice} />
      <div className="font-semibold text-xl py-2 text-start">Price Range</div>
      <div className="py-4 relative">
        <div
          className="grid grid-cols-7 gap-2 content-center items-center"
          aria-hidden
          onClick={(e) => e.stopPropagation()}
        >
          <PriceInput
            placeholder="min"
            onChange={(e) => setMinPrice(formattedValue(e))}
            value={minPrice}
            rangeIsValid={rangeIsValid}
            className="border border-gray-500"
          />
          <span className="w-8 flex justify-center mx-auto">
            <Image
              src={RangeDash}
              width="48"
              height="38"
              layout="fixed"
              alt="arrow"
            />
          </span>
          <PriceInput
            placeholder="max"
            onChange={(e) => setMaxPrice(formattedValue(e))}
            value={maxPrice}
            rangeIsValid={rangeIsValid}
          />
        </div>
        {!rangeIsValid ? (
          <div className="absolute text-red">
            Minimum price should be less than the maximum.
          </div>
        ) : null}
      </div>
    </FilterContainer>
  );
}
