import React from 'react';

function PetPolicyCheckbox({ petPolicy, setPetPolicy }) {
  return (
    <label
      htmlFor="petPolicyCheckbox"
      className="custom-checkbox flex align-top"
    >
      <input
        id="petPolicyCheckbox"
        type="checkbox"
        name="checkbox"
        checked={petPolicy}
        onChange={() => setPetPolicy(!petPolicy)}
        className="checked:bg-purple border w-[40px] mr-2 align-stretch border-purple-800"
        aria-label="Pet policy"
        tabIndex="0"
      />

      <div className="text-xs font-light cursor-pointer">
        Check this box if you <strong>only</strong> want to include listings
        that explicitly state pet policy. Many landlords don’t provide pet
        policy information but <strong>will</strong> accept pets. Checking this
        box may significantly limit your results.
      </div>
    </label>
  );
}

export default PetPolicyCheckbox;
