import Accordion from 'components/form/Accordion';
import React from 'react';

import BedBathIndicator from './BedBathIndicator';
import BedroomSelector from './BedroomSelector';

function RoomsAccordion({
  store,
  active,
  setActive,
  beds,
  setBeds,
  bdExact,
  setBdExact,
  baths,
  setBaths,
  baExact,
  setBaExact,
}) {
  return (
    <Accordion
      active={active}
      setActive={setActive}
      id="rooms-accordion"
      title={
        <div className="flex flex-col px-6 justify-center w-full h-14 text-sm rounded-full bg-[#E7E7F3]">
          <div className="leading-none mb-1 text-start">Rooms</div>
          <div className="flex text-lg leading-none">
            <BedBathIndicator
              filters={store?.filters}
              bedrooms={beds}
              bathrooms={baths}
            />
          </div>
        </div>
      }
      content={
        <div className="flex w-full text-sm rounded-3xl overflow-hidden drop-shadow-2xl">
          <BedroomSelector
            beds={beds}
            setBeds={setBeds}
            bdExact={bdExact}
            setBdExact={setBdExact}
            baths={baths}
            setBaths={setBaths}
            baExact={baExact}
            setBaExact={setBaExact}
          />
        </div>
      }
    />
  );
}

export default RoomsAccordion;
