import Accordion from 'components/form/Accordion';
import React from 'react';

import PriceIndicator from './PriceIndicator';
import PriceSelector from './PriceSelector';
import testPriceValidity from './testPriceValidity';

function PriceAccordion({
  store,
  active,
  setActive,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
}) {
  const rangeIsValid = testPriceValidity(minPrice, maxPrice);

  if (!rangeIsValid) setActive('price-accordion');

  return (
    <Accordion
      active={active}
      setActive={setActive}
      id="price-accordion"
      title={
        <div className="flex flex-col px-6 justify-center w-full h-14 text-sm rounded-full bg-[#E7E7F3]">
          <div className="leading-none mb-1 text-left">Price Range</div>
          <div className="flex text-lg leading-none">
            <PriceIndicator
              filters={store?.filters}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
          </div>
        </div>
      }
      content={
        <div className="flex w-full text-sm rounded-3xl overflow-hidden drop-shadow-2xl">
          <PriceSelector
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
          />
        </div>
      }
    />
  );
}

export default PriceAccordion;
