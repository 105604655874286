import Image from 'next/image';
import CheckIcon from 'public/images/check-green.svg';
import React from 'react';

function MoreButtons({ list, button, buttonLabel, onClick }) {
  const mapping = (item, index) => {
    const selected =
      (Array.isArray(button) && button.some((el) => el === item)) ||
      button === item ||
      button === true;
    return (
      <div
        className="flex flex-col  max-w-[120px] md:max-w-[180px] flex-1"
        key={`${item}-button`}
      >
        <button
          type="button"
          className={`flex relative h-14 border justify-center align-middle rounded-xl ${
            selected ? 'bg-purple text-white sm:p-3' : 'bg-slate-50 sm:p-3'
          }`}
          onClick={onClick}
        >
          {selected ? (
            <Image
              src={CheckIcon.src}
              alt="check"
              width="12"
              height="12"
              className="self-center mr-1"
            />
          ) : null}
          <div className="text-sm sm:text-base lg:text-lg self-center">
            {item}
          </div>
        </button>
        {buttonLabel ? (
          <div className="text-[9px] font-light text-center">
            {buttonLabel[index]}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div
      key={`${list[0]}-section`}
      className="flex flex-wrap gap-1 mb-8 mt-1 mx-0 place-content-start"
    >
      {list.map((item, index) => mapping(item, index))}
    </div>
  );
}

export default MoreButtons;
